import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/points/point_icon.png';
var _hoisted_1 = {
  class: "pts"
};
import { computed } from "vue";
import { useUserStore } from "@/store/userStore.js";
import { useSupplierStore } from "@/store/supplierStore.js";
export default {
  __name: 'RewardPoints',
  setup: function setup(__props) {
    var userStore = useUserStore();
    var supplierStore = useSupplierStore();
    var rewardsImgUrl = computed(function () {
      var _supplierStore$getSup;
      return (_supplierStore$getSup = supplierStore.getSupplierImageSetting) === null || _supplierStore$getSup === void 0 || (_supplierStore$getSup = _supplierStore$getSup.rewards_page_image) === null || _supplierStore$getSup === void 0 ? void 0 : _supplierStore$getSup.image_url;
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "reward-points",
        style: _normalizeStyle("background-image:url(".concat(rewardsImgUrl.value || require("@/assets/images/rewards/rewards_header.png"), ")"))
      }, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "title"
      }, "Rewards Center", -1)), _createElementVNode("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0,
        class: "rewards-pts-img"
      }, null, -1)), _createTextVNode(" " + _toDisplayString(_unref(userStore).getPoints) + " ", 1), _cache[1] || (_cache[1] = _createElementVNode("span", {
        class: "pts"
      }, "pts", -1))])], 4);
    };
  }
};