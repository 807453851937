import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "balance-footer"
};
import { ref } from "vue";
import { useRouter } from "@/hooks/useRouter";
import { useRoute } from "vue-router";
import AccountBalanceSum from "@/components/account/AccountBalanceSum.vue";
import AccountBalanceRecord from "@/components/account/AccountBalanceRecord.vue";
import { useSupplierStore } from "@/store/supplierStore";
import { computed } from "vue";
export default {
  __name: 'MyBalance',
  setup: function setup(__props) {
    var route = useRoute();
    var _useRouter = useRouter(),
      routerPush = _useRouter.routerPush,
      routerBack = _useRouter.routerBack;
    var activeName = ref("all");
    var supplierStore = useSupplierStore();
    var allowReload = computed(function () {
      var _supplierStore$getSup, _supplierStore$getSup2;
      return (_supplierStore$getSup = (_supplierStore$getSup2 = supplierStore.getSupplierTopUpSetting) === null || _supplierStore$getSup2 === void 0 ? void 0 : _supplierStore$getSup2.allow_top_up_at_mini_program) !== null && _supplierStore$getSup !== void 0 ? _supplierStore$getSup : true;
    });

    // 页面后退
    function handlePageBack() {
      if (route.query.back_to_account) {
        routerPush({
          name: "Account"
        });
      } else {
        routerBack();
      }
    }
    function goToRecharge() {
      routerPush({
        name: "Recharge",
        query: {
          redirect: "my_balance"
        }
      });
    }
    return function (_ctx, _cache) {
      var _component_van_tab = _resolveComponent("van-tab");
      var _component_van_tabs = _resolveComponent("van-tabs");
      var _component_Button = _resolveComponent("Button");
      var _component_Page = _resolveComponent("Page");
      return _openBlock(), _createBlock(_component_Page, {
        title: "Balance detail",
        class: "balance-list",
        "show-footer": allowReload.value,
        "on-back": handlePageBack
      }, {
        footer: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_Button, {
            type: "content",
            class: "balance-footer-btn",
            onClick: goToRecharge
          }, {
            default: _withCtx(function () {
              return _cache[1] || (_cache[1] = [_createTextVNode("Reload")]);
            }),
            _: 1
          })])];
        }),
        default: _withCtx(function () {
          return [_createVNode(_component_van_tabs, {
            active: activeName.value,
            "onUpdate:active": _cache[0] || (_cache[0] = function ($event) {
              return activeName.value = $event;
            }),
            class: "balance-list_tabs"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_van_tab, {
                title: "All",
                name: "all"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(AccountBalanceSum), _createVNode(AccountBalanceRecord)];
                }),
                _: 1
              }), _createVNode(_component_van_tab, {
                title: "Increase",
                name: "income"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(AccountBalanceRecord, {
                    "change-type": 'income'
                  })];
                }),
                _: 1
              }), _createVNode(_component_van_tab, {
                title: "Reduce",
                name: "expenditure"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(AccountBalanceRecord, {
                    "change-type": 'expense'
                  })];
                }),
                _: 1
              })];
            }),
            _: 1
          }, 8, ["active"])];
        }),
        _: 1
      }, 8, ["show-footer"]);
    };
  }
};