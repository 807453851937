import { defineStore } from "pinia";
import store from "./index";
import { getAllShopBySupplier } from "@/api/shop";
import { getSupplierSetting } from "@/api/supplier";
import { setCssVar, setFavicon } from "@/utils/domUtil";
import { getTestSupplierId } from "@/utils/authUtil";
import { getBusinessTrigger } from "@/api/reward";
import { useShopStoreWithOut } from "./shopStore";
import { getInvoiceSetting } from "@/api/invoice";

import { priceUtil } from "@op/shared";
export const useSupplierStore = defineStore({
  id: "supplier",
  state: () => {
    return {
      shopList: [],
      setting: {
        country: "",
        image_setting: {},
        supplier_id: "",
        whatsapp_id: "",
        supplier_name: "",
        style_setting: {
          theme_color: "",
          home_background_color: "",
        },
        order_setting: {
          self_delivery_status: false,
          self_pickup_status: false,
        },
        customer_setting: {
          collect_customer_info: "off",
        },
      },
      invoiceSetting: [],
      supplierId: "", // 与setting里的supplier_id一致
      businessTriggerList: [],
      hasSetBusinessTriggerList: false,
    };
  },
  getters: {
    getShopList(state) {
      return state.shopList;
    },
    getCountry(state) {
      return state.setting.country;
    },
    getSupplierId(state) {
      return state.setting.supplier_id;
    },
    getSupplierImageSetting(state) {
      return state.setting.image_setting;
    },
    getSupplierPickupImage(state) {
      return state.setting.image_setting.function_image?.pickup_image
        ?.image_url;
    },
    getSupplierDeliveryImage(state) {
      return state.setting.image_setting.function_image?.delivery_image
        ?.image_url;
    },
    getSupplierPureLogo(state) {
      return state.setting.image_setting.pure_logo_image?.image_url;
    },
    getBottomNavSetting(state) {
      return state.setting.image_setting.bottom_navigation_bar || [];
    },
    getSupplierColorSetting(state) {
      return state.setting.image_setting;
    },
    getSupplierName(state) {
      return state.setting.supplier_name;
    },
    getWhatsappid(state) {
      return state.setting.whatsapp_id;
    },
    getBusinessTriggerList(state) {
      return state.businessTriggerList;
    },
    getSupplierOrderSetting(state) {
      return state.setting.order_setting;
    },
    getIsSupplierSelfDeliverStatus(state) {
      return state.setting.order_setting.self_delivery_status;
    },
    getIsSupplierSelfPickupStatus(state) {
      return state.setting.order_setting.self_pickup_status;
    },
    getLoginBackgroundImage(state) {
      return state.setting.image_setting.login_background_image?.image_url;
    },
    getIsSMSOpen(state) {
      return (
        state.setting.authentication_setting?.sms_verification_status === "on"
      );
    },
    getIsLoginOpen(state) {
      return !!state.setting.authentication_setting?.customer_login_channels?.find(
        (c) => c.status === "on"
      );
    },
    getIsGoogleLoginOpen(state) {
      return (
        state.setting.authentication_setting?.customer_login_channels?.find(
          (c) => c.login_channel === "Google"
        )?.status === "on"
      );
    },
    getIsAppleLoginOpen(state) {
      return (
        state.setting.authentication_setting?.customer_login_channels?.find(
          (c) => c.login_channel === "Apple"
        )?.status === "on"
      );
    },
    getIsMobileLoginOpen(state) {
      return (
        state.setting.authentication_setting?.customer_login_channels?.find(
          (c) => c.login_channel === "Mobile"
        )?.status === "on"
      );
    },
    getIsCollectCustomerInfo(state) {
      return state.setting.customer_setting?.collect_customer_info === "on";
    },
    getSupplierTopUpSetting(state) {
      return state.setting.top_up_setting;
    },
  },
  actions: {
    async setShopList() {
      const shopRes = await getAllShopBySupplier({
        supplier_id: this.setting.supplier_id,
      });
      if (shopRes.isOK && shopRes.data) {
        const data = shopRes.data.shop_list || [];
        this.shopList = data;

        this.shopList = this.shopList.filter(
          (shopItem) =>
            shopItem.self_pickup_setting?.is_accept ||
            shopItem.self_delivery_setting?.is_accept
        );
      }
    },

    async setSupplierSetting() {
      const shopStore = useShopStoreWithOut();
      const oldSupplierId = this.supplierId;

      if (window.__CONFIG__) {
        this.setting = window.__CONFIG__;
        this.supplierId = window.__CONFIG__.supplier_id || "";
        this.shopList = window.__CONFIG__.shop_list || [];
        this.shopList = this.shopList.filter(
          (shopItem) =>
            shopItem.self_pickup_setting?.is_accept ||
            shopItem.self_delivery_setting?.is_accept
        );
      } else {
        const res = await getSupplierSetting(
          this.setting.supplier_id || getTestSupplierId()
        );
        if (res.isOK) {
          this.setting = res.data;
          this.supplierId = res.data.supplier_id || "";
        } else {
          this.setting.supplier_id = getTestSupplierId();
          this.supplierId = getTestSupplierId();
          return;
        }
        await this.setShopList();
      }

      /**
       * 未登录时，不会自动选中店铺，此时没有货币配置
       * 活动页不用登录也可以访问，没有货币配置会有问题
       * 默认取第一个店铺的货币配置
       */
      if (this.shopList.length > 0) {
        if (this.shopList[0].currency_setting) {
          priceUtil.setSettings(this.shopList[0].currency_setting);
        } else {
          console.error("no supplier currency setting");
        }
      }

      // 将order_setting中的两个状态为转换成布尔类型
      this.setting.order_setting = {
        self_delivery_status:
          this.setting.order_setting?.self_delivery_status === "on",
        self_pickup_status:
          this.setting.order_setting?.self_pickup_status === "on",
      };

      // 访问的页面的supplierId跟之前访问过的不一致，则清空掉本地存储的店铺数据
      if (oldSupplierId !== this.supplierId && shopStore.getShopId) {
        shopStore.setShopId("");
      }

      // 预加载头图
      if (this.setting.image_setting?.main_image?.image_url) {
        new Image().src = this.setting.image_setting?.main_image?.image_url;
      }
      // set colors
      setCssVar(
        "--op-primary-color",
        this.setting.style_setting?.theme_color || "#d11016"
      );

      setCssVar(
        "--op-home-bg-color",
        this.setting.style_setting?.home_background_color || "#f5f5f5"
      );

      if (this.setting.image_setting?.pure_logo_image?.image_url) {
        setFavicon(this.setting.image_setting?.pure_logo_image?.image_url);
      }
    },
    async setBusinessTriggerList() {
      if (this.hasSetBusinessTriggerList) {
        return;
      }

      // 获取营销触点配置
      const res = await getBusinessTrigger({
        supplier_id: this.getSupplierId,
      });
      if (res.isOK) {
        this.businessTriggerList = res.data.item || [];
        this.hasSetBusinessTriggerList = true;
      }
    },
    async setInvoiceSetting() {
      const res = await getInvoiceSetting();
      if (res.code === 0) {
        this.invoiceSetting = res.data.items || [];
      }
    },
    getInvoiceSelfServiceEnable(shopId) {
      const setting = this.invoiceSetting.find((s) => s.shop_id === shopId);
      if (!setting) return false;
      return setting.enable && setting.self_service_invoicing;
    },
    getInvoiceSelfServiceOne() {
      for (const setting of this.invoiceSetting) {
        if (setting.enable && setting.self_service_invoicing) {
          return true;
        }
      }
      return false;
    },
  },
  persist: {
    enable: true,
    key: "SUPPLIER",
    includes: ["supplierId"], // supplierId存在本地，是为了进入不同公司的页面后，需要清空本地存储的shopId
  },
});

export function useSupplierStoreWithOut() {
  return useSupplierStore(store);
}
